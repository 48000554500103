import * as React from 'react'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'

const genURL = (keyword: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/addresses?keyword=${keyword}`
  return url
}

const AddressesPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [addresses, setAddresses] = React.useState<any[]>([])
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')

  const fetchAddresses = React.useCallback(() => {
    const url = genURL(filterKeyword)
    // 配送元情報の取得
    axios
      .get(url)
      .then((res) => {
        if (res.data.data) {
          setAddresses(res.data.data)
        } else if (res.data.data === null) {
          setAddresses([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [filterKeyword])

  // 初期化
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      axios.defaults.headers.common['Authorization'] = t
      fetchAddresses()
    })
  }, [getTokenSilently, fetchAddresses])

  if (!addresses) {
    return <Loading text="配送先・配送元情報読み込み中..." />
  }

  const columns = [
    {
      title: 'ユーザーID',
      field: 'user_id',
      hozAlign: 'center',
      width: 80,
      sorter: 'number',
    },
    {
      title: 'ユーザー名',
      field: 'user_name',
      hozAlign: 'left',
      width: 220,
    },
    {
      title: '向',
      field: 'direction',
      hozAlign: 'center',
      width: 40,
      formatter: (cell: any, formatterParams: any) => {
        var value = cell.getValue()
        if (value === '配送元') {
          return "<span style='background-color:#0864CE;color:white;font-weight:bold;padding:4px;'>元</span>"
        } else {
          return "<span style='background-color:#E89A48;color:white;font-weight:bold;padding:4px;'>先</span>"
        }
      },
    },
    {
      title: 'ID',
      field: 'id',
      hozAlign: 'right',
      width: 80,
      sorter: 'number',
      visible: false,
    },
    {
      title: '組織名',
      field: 'organization',
      hozAlign: 'left',
      width: 200,
      editor: 'input',
    },
    {
      title: '姓',
      field: 'family_name',
      hozAlign: 'left',
      width: 110,
      editor: 'input',
    },
    {
      title: '名',
      field: 'given_name',
      hozAlign: 'left',
      width: 110,
      editor: 'input',
    },
    {
      title: 'セイ',
      field: 'family_kana',
      hozAlign: 'left',
      width: 110,
      editor: 'input',
    },
    {
      title: 'メイ',
      field: 'given_kana',
      hozAlign: 'left',
      width: 110,
      editor: 'input',
    },
    {
      title: '電話番号',
      field: 'tel',
      hozAlign: 'left',
      width: 150,
      editor: 'input',
    },
    {
      title: '郵便番号',
      field: 'postal_code',
      hozAlign: 'left',
      width: 120,
      editor: 'number',
    },
    {
      title: '都道府県',
      field: 'address_level1',
      hozAlign: 'left',
      width: 100,
      editor: 'input',
    },
    {
      title: '市区町村',
      field: 'address_level2',
      hozAlign: 'left',
      width: 150,
      editor: 'input',
    },
    {
      title: '以降の住所',
      field: 'address_line1',
      hozAlign: 'left',
      width: 400,
      editor: 'input',
    },
    {
      title: 'ビル名、部屋番号',
      field: 'address_line2',
      hozAlign: 'left',
      width: 400,
      editor: 'input',
    },
  ]

  const handleInputKeyword = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setFilterKeyword(ev.currentTarget['filter-keyword'].value)
  }

  const handleDelete = (data: any) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/address-delete`
    axios({
      url: url,
      method: 'post',
      data: data,
    })
      .then(() => {
        fetchAddresses()
      })
      .catch((err) => {
        alert('エラーが発生しました')
        console.error(err)
      })
  }

  const handleUpdate = (data: any) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/address`
    axios({
      url: url,
      method: 'put',
      data: data,
    })
      .then(() => {
        fetchAddresses()
      })
      .catch((err) => {
        alert('エラーが発生しました')
        console.error(err)
      })
  }

  return (
    <div className="flex flex-col">
      <div className="h-12 p-2 flex justify-between">
        <div className="flex items-center justify-start">
          <form onSubmit={handleInputKeyword} className="flex">
            <span className="text-xs pr-2 text-right">
              ユーザーIDまたは
              <br />
              ユーザー名でフィルタ
            </span>{' '}
            <input
              className="border border-solid border-gray-300 rounded px-2"
              name="filter-keyword"
              type="text"
              defaultValue={filterKeyword}
            />
            <button type="submit" className="hidden" />
          </form>
          <button
            className="border px-4 rounded hover:bg-gray-200 mx-2"
            onClick={fetchAddresses}
          >
            データ再取得
          </button>
          <div>※行を右クリックすると「削除」ボタンが出てきます</div>
        </div>
      </div>
      <ReactTabulator
        height={window.innerHeight - 80}
        data={addresses}
        columns={columns}
        tooltips={true}
        layout="fitData"
        cellEdited={(cell: any) => {
          const ok = window.confirm(
            [
              '本当に変更してよろしいですか？',
              '\n変更前:',
              cell._cell.oldValue,
              '\n変更後:',
              cell._cell.value,
            ].join(' '),
          )
          if (ok) {
            handleUpdate(cell._cell.row.data)
          } else {
            cell.restoreOldValue()
          }
        }}
        options={{
          movableColumns: true,
          initialSort: [
            { column: 'id', dir: 'asc' },
            { column: 'direction', dir: 'asc' },
            { column: 'user_id', dir: 'asc' },
          ],
          rowContextMenu: [
            {
              label: '削除',
              action: (e: any, row: any) => {
                const data = row._row.data
                const ok = window.confirm('本当によろしいですか？')
                if (ok) {
                  handleDelete(data)
                }
              },
            },
          ],
        }}
      />
    </div>
  )
}

export default AddressesPage
