import * as React from 'react'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import humanize from 'humanize-plus'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const numOrBlank = (cell: any) => {
  const v = cell.getValue()
  if (v === 0) {
    return ''
  }
  return v
}

const OrdersPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [orders, setOrders] = React.useState<any[] | null>(null)
  const [groupBy, setGroupBy] = React.useState('orderDate')
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')
  const [filterExampleUse, setFilterExampleUse] = React.useState<0 | 1>(0)
  const tableRef = React.useRef<any>()
  const query = useQuery()

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
      axios.defaults.headers.common['Authorization'] = t
    })
  }, [getTokenSilently])

  React.useEffect(() => {
    const k = query.get('k')
    if (k) {
      setFilterKeyword(k)
    }
  }, [query])

  React.useEffect(() => {
    const groupBy = localStorage.getItem('groupBy')
    if (groupBy) {
      setGroupBy(groupBy)
    }
  }, [])

  const fetchOrders = React.useCallback(() => {
    setOrders(null)
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/ordersummaries?keyword=${filterKeyword}&exampleUse=${filterExampleUse}`,
      )
      .then((res) => {
        if (res.data.data) {
          setOrders(res.data.data)
        } else if (res.data.data === null) {
          setOrders([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [filterKeyword, filterExampleUse])

  // データ取得
  React.useEffect(() => {
    if (token === '') {
      return
    }
    fetchOrders()
  }, [token, fetchOrders])

  React.useEffect(() => {
    if (!tableRef || !tableRef.current) {
      return
    }
    const table = tableRef.current.table
    if (!table) {
      return
    }
    if (groupBy === 'shippingDateText') {
      table.setFilter('shippingDate', '>', '0')
      table.setSort([{ column: 'shippingDate', dir: 'asc' }])
    } else {
      table.clearFilter()
      table.clearSort()
    }
  }, [tableRef, groupBy])

  if (!orders) {
    return <Loading text="注文情報読み込み中..." />
  }

  const navigateToOrder = (e: any, cell: any) => {
    window.open(`/orders/${cell.getData().orderId}`)
  }

  const toggleAdditionalInformation = (e: any, cell: any) => {
    const orderId = cell.getData().orderId
    cell.setValue(!cell.getValue())
    const data = {
      order_id: orderId,
      printed: cell.getData().printed,
      cut: cell.getData().cut,
    }
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/order-additional-information`,
      method: 'post',
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('ok')
        }
      })
      .catch((err) => {
        console.error(err)
        window.alert('エラーが発生しました')
      })
  }

  const columns = [
    {
      title: '支払番号',
      field: 'purchaseId',
      width: 100,
      cellClick: navigateToOrder,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '注文番号',
      field: 'orderId',
      align: 'center',
      width: 130,
      cellClick: navigateToOrder,
      formatter: (cell: any) => {
        const v = cell.getValue()
        if (v === 0) {
          return '--'
        }
        return v
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: 'リピート',
      field: 'repeatId',
      align: 'center',
      width: 130,
      cellClick: navigateToOrder,
      formatter: (cell: any) => {
        const v = cell.getValue()
        if (v === 0) {
          return '--'
        }
        return v
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '注文日時',
      field: 'orderDateTime',
      align: 'center',
      width: 150,
      cellClick: navigateToOrder,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD HH:mm:ss')
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '発送予定日',
      field: 'shippingDate',
      align: 'center',
      width: 110,
      cellClick: navigateToOrder,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD')
      },
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '組織名',
      field: 'organization',
      align: 'left',
      width: 150,
      cellClick: navigateToOrder,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '氏名',
      field: 'userName',
      align: 'left',
      width: 80,
      cellClick: navigateToOrder,
      frozen: window.innerWidth > 1280 ? true : false,
    },
    {
      title: '商品名',
      field: 'orderName',
      width: 200,
      cellClick: navigateToOrder,
    },
    {
      title: 'ステータス',
      field: 'status',
      align: 'center',
      width: 120,
      cellClick: navigateToOrder,
      formatter: 'lookup',
      formatterParams: {
        '0': '不明',
        '1': '注文完了',
        '2': '支払完了',
        '3': '入稿完了',
        '4': '再入稿待ち',
        '5': 'データチェック完了',
        '7': '印刷完了',
        '8': '発送完了',
        '9': '納品完了',
        '10': '入金待ち',
        '12': 'お客様確認中',
        '90': 'キャンセル依頼完了',
        '91': '全額返金完了',
        '92': '規定額返金完了',
        '99': 'キャンセル完了',
      },
    },
    {
      title: '材質',
      field: 'materialName',
      width: 120,
      cellClick: navigateToOrder,
    },
    {
      title: 'ラミネート加工',
      field: 'lamination',
      width: 120,
      cellClick: navigateToOrder,
    },
    {
      title: 'X(&#13212;)',
      field: 'sizeX',
      align: 'right',
      width: 50,
      cellClick: navigateToOrder,
      headerSort: false,
    },
    {
      title: 'Y(&#13212;)',
      field: 'sizeY',
      align: 'right',
      width: 50,
      cellClick: navigateToOrder,
      headerSort: false,
    },
    {
      title: 'ｻｲｽﾞ(&#13216;)',
      field: 'size',
      align: 'right',
      width: 60,
      cellClick: navigateToOrder,
      headerSort: false,
    },
    {
      title: '枚数',
      field: 'num',
      align: 'right',
      width: 70,
      cellClick: navigateToOrder,
    },
    {
      title: 'JV300',
      field: 'sheetNumJV300',
      align: 'right',
      width: 72,
      cellClick: navigateToOrder,
      formatter: numOrBlank,
      headerSort: false,
    },
    {
      title: 'CJV150',
      field: 'sheetNumCJV150',
      align: 'right',
      width: 72,
      cellClick: navigateToOrder,
      formatter: numOrBlank,
      headerSort: false,
    },
    {
      title: 'V180',
      field: 'sheetNumV180',
      align: 'right',
      width: 72,
      cellClick: navigateToOrder,
      formatter: numOrBlank,
      headerSort: false,
    },
    {
      title: '商品価格',
      field: 'amountProduct',
      align: 'right',
      width: 80,
      cellClick: navigateToOrder,
      formatter: 'money',
      formatterParams: { thousand: ',', symbol: '¥', precision: false },
      headerSort: false,
    },
    {
      title: 'オプション価格',
      field: 'amountOption',
      align: 'right',
      width: 80,
      cellClick: navigateToOrder,
      formatter: 'money',
      formatterParams: { thousand: ',', symbol: '¥', precision: false },
      headerSort: false,
    },
    {
      title: 'ポイント数',
      field: 'point',
      align: 'right',
      width: 70,
      cellClick: navigateToOrder,
      headerSort: false,
    },
    {
      title: '支払い方法',
      field: 'purchaseMethod',
      align: 'center',
      width: 80,
      cellClick: navigateToOrder,
      formatter: 'lookup',
      formatterParams: {
        epsilon: 'カード',
        bank: '銀行振込',
        onDelivery: '代金引換',
        gmoab: '後払い',
        vbank: 'V口座',
        paypay: 'PayPay',
        point: '全額P',
      },
      headerSort: false,
    },
    {
      title: '備考',
      field: 'memo',
      align: 'left',
      width: 80,
      cellClick: navigateToOrder,
      headerSort: false,
    },
    {
      title: '印刷',
      field: 'printed',
      width: 40,
      align: 'center',
      cssClass: 'hover:opacity-50',
      cellClick: toggleAdditionalInformation,
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
    {
      title: 'ｶｯﾄ',
      field: 'cut',
      width: 40,
      align: 'center',
      cssClass: 'hover:opacity-50',
      cellClick: toggleAdditionalInformation,
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
    {
      title: '紹介',
      field: 'canExampleUse',
      width: 40,
      align: 'center',
      cssClass: 'cursor-default',
      formatter: 'tickCross',
      formatterParams: {
        allowEmpty: false,
        allowTruthy: true,
        tickElement:
          "<span class='text-green-700'><i class='fa fa-check-square'></i></span>",
        crossElement:
          "<span class='text-gray-700'><i class='far fa-square'></i></span>",
      },
      headerSort: false,
    },
  ]

  const handleInputKeyword = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const k = ev.currentTarget['filter-keyword'].value
    window.history.pushState(null, '', `?k=${k}`)
    setFilterKeyword(k)
  }

  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeOrders-filter', '')
    localStorage.setItem('tabulator-backofficeOrders-sort', '')
    localStorage.setItem('tabulator-backofficeOrders-columns', '')
    window.location.reload()
  }
  const todayText = dayjs().format('YYYY年MM月DD日')
  return (
    <div className="flex flex-col">
      <div className="h-8 p-2 flex justify-between">
        <div className="flex items-center justify-start">
          <form onSubmit={handleInputKeyword}>
            フィルタ:{' '}
            <input
              className="border border-solid border-gray-300 rounded px-2"
              name="filter-keyword"
              type="text"
              defaultValue={filterKeyword}
            />
            <button type="submit" className="hidden" />
          </form>
          <button
            className="border border-solid border-gray-300 px-4 rounded hover:bg-gray-200 mx-2"
            onClick={fetchOrders}
          >
            データ再取得
          </button>
          <label className="mx-1 flex items-center justify-center">
            <input
              type="checkbox"
              name="filterExampleUse"
              checked={filterExampleUse === 1}
              onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                if (ev.currentTarget.checked) {
                  setFilterExampleUse(1)
                } else {
                  setFilterExampleUse(0)
                }
              }}
            />
            <span className="text-sm ml-1">紹介OK</span>
          </label>
          <div className="flex text-sm border ml-2">
            <span className="bg-gray-200 p-1">表示切り替え: </span>
            <label className="px-1 flex items-center justify-center hover:bg-gray-100 cursor-pointer">
              <input
                type="radio"
                name="groupby"
                checked={groupBy === 'orderDate'}
                onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                  if (ev.currentTarget.checked) {
                    setGroupBy('orderDate')
                    localStorage.setItem('groupBy', 'orderDate')
                  }
                }}
              />
              <span className="text-sm ml-1">注文日ごと</span>
            </label>
            <label className="px-1 flex items-center justify-center hover:bg-gray-100 cursor-pointer">
              <input
                type="radio"
                name="groupby"
                checked={groupBy === 'shippingDateText'}
                onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => {
                  if (ev.currentTarget.checked) {
                    setGroupBy('shippingDateText')
                    localStorage.setItem('groupBy', 'shippingDateText')
                  }
                }}
              />
              <span className="text-sm ml-1">発送予定日ごと</span>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <button
            className="border border-solid border-gray-300 px-4 rounded hover:bg-gray-200 mx-2"
            onClick={clearTabulatorPersistence}
          >
            テーブル設定初期化
          </button>
        </div>
      </div>
      <ReactTabulator
        ref={tableRef}
        height={window.innerHeight - 80}
        data={orders}
        columns={columns}
        tooltips={true}
        layout="fitColumns"
        options={{
          groupBy: groupBy,
          persistence: true,
          persistenceMode: 'local',
          persistenceID: 'backofficeOrders',
          persistenceLayout: true,
          movableColumns: true,
          virtualDomBuffer: 20000,
          groupStartOpen: (
            value: any,
            count: number,
            data: any,
            group: any,
          ) => {
            if (groupBy === 'orderDate') {
              return true
            }
            let open = false
            if (value.startsWith(todayText)) {
              open = true
            }
            return open //all groups with more than three rows start open, any with three or less start closed
          },
          groupToggleElement: 'header',
          groupHeader: function (
            value: any,
            count: number,
            data: any,
            group: any,
          ) {
            let unshipped = 0
            let unprinted = 0
            let uncut = 0
            let amount = 0
            let jv300 = 0
            let cjv150 = 0
            let v180 = 0
            for (const d of data) {
              if (![8, 9].includes(d.status)) {
                unshipped++
              }
              if (d.printed === false) unprinted++
              if (d.cut === false) uncut++
              amount = amount + d.amountProduct + d.amountOption
              jv300 = jv300 + d.sheetNumJV300
              cjv150 = cjv150 + d.sheetNumCJV150
              v180 = v180 + d.sheetNumV180
            }

            if (groupBy === 'orderDate') {
              return `<div class="flex items-center">
                <span><big class="font-bold">${value}</big></span>
                <span style='margin-left:10px;width:40px;text-align:right;'>${count}件</span>
              ${
                Number(amount) > 0
                  ? `
              <span style='width:120px;text-align:right;'>
                <big style='margin-left:10px;font-weight:700;color:#a00;'>&yen;${humanize.intComma(
                  amount,
                )}</big>
              </span>
              `
                  : `<span></span>`
              }
              </div>`
            }
            return `<div class="flex items-center">
              <span><big class="font-bold">${value}</big></span>
              <span style='margin-left:10px;width:40px;text-align:right;'>${count}件</span>
              <div class="flex items-center" style='margin-left:10px;'>
                <div>[</div>
                <div style="width: 120px;"><span>未印刷:</span> <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${unprinted}</big></span></div>
                <div style="width: 120px;"><span>未カット:</span><span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${uncut}</big></span></div>
                <div style="width: 120px;"><span>未発送:</span>  <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700;color:#a00;'>${unshipped}</big></span></div>
                <div>]</div>
              </div>
              <div class="flex items-center" style='margin-left:10px;'>
                <div>[</div>
                <div style="width: 120px;"><span>JV300:</span> <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700'>${jv300}</big></span></div>
                <div style="width: 120px;"><span>CJV150:</span><span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700'>${cjv150}</big></span></div>
                <div style="width: 120px;"><span>V180:</span>  <span style="display:inline-block;text-align:right;width:40px;"><big style='font-weight:700'>${v180}</big></span></div>
                <div>]</div>
              </div>
              ${
                Number(amount) > 0
                  ? `
              <span style='width:120px;text-align:right;'>
                <big style='margin-left:10px;font-weight:700;color:#a00;'>&yen;${humanize.intComma(
                  amount,
                )}</big>
              </span>
              `
                  : `<span></span>`
              }
            </div>`
          },
        }}
      />
    </div>
  )
}

export default OrdersPage
