export const tidyUpProducts = (products: any[]) => {
  const result: any = {}
  // 材質
  result.material = products
    .filter((p) => {
      return [
        13, 14, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
      ].includes(p.category)
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // ラミネーション
  result.lamination = products
    .filter((p) => {
      return p.category === 3
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // 接着のり
  result.adhesive = products
    .filter((p) => {
      return p.category === 4
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // ハーフカット
  result.halfcut = products
    .filter((p) => {
      return p.category === 5
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // カットパス
  result.cutpath = products
    .filter((p) => {
      return p.category === 6
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // 裏スリット
  result.slit = products
    .filter((p) => {
      return p.category === 7
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // 裏面印刷
  result.backSide = products
    .filter((p) => {
      return p.category === 37
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // 納品形態
  result.deliveryForm = products
    .filter((p) => {
      return p.category === 8
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // ホワイトインク
  result.whiteInk = products
    .filter((p) => {
      return p.category === 33
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // 控え発送
  result.sendCopy = products
    .filter((p) => {
      return p.category === 34
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // カットライン作成代行サービス
  result.cutlineService = products
    .filter((p) => {
      return p.category === 35
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  // ホワイトデータ作成代行サービス
  result.whitedataService = products
    .filter((p) => {
      return p.category === 36
    })
    .map((p) => {
      return {
        name: p.name,
        value: p.id,
      }
    })

  return result
}

export const productNameById = (
  products: any,
  productType: string,
  id: number,
): string => {
  for (const p of products[productType]) {
    if (p.value === id) {
      return p.name
    }
  }
  return ''
}
