import * as React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  purchase: any
}

const Purchase: React.FC<Props> = (props) => {
  const { purchase } = props
  return (
    <section className="p-2">
      <h2 className="text-3xl font-bold">支払い情報</h2>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">支払番号</th>
            <td className="border w-3/5 p-1">
              <a
                className="text-blue-500 hover:text-blue-700 active:text-blue-900"
                href={`/orders?k=${purchase.purchase_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {purchase.purchase_id}
              </a>
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">支払方法</th>
            <td className="border w-3/5 p-1">{purchase.method}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">商品代金合計</th>
            <td className="border w-3/5 p-1">{purchase.total_amount}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">ポイント利用数</th>
            <td className="border w-3/5 p-1">
              {Math.abs(purchase.used_point)}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">代引手数料</th>
            <td className="border w-3/5 p-1">{purchase.commission}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">支払い金額合計</th>
            <td className="border w-3/5 p-1">
              {purchase.total_amount +
                purchase.used_point +
                purchase.commission}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">備考</th>
            <td className="border w-3/5 p-1">{purchase.note}</td>
          </tr>
        </tbody>
      </table>
      <h2 className="text-3xl font-bold mt-4">配送先・配送元情報</h2>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="border bg-gray-200" colSpan={2}>
              配送先
            </th>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">組織名</th>
            <td className="border w-3/5 p-1">
              {purchase.dest_organization}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">氏名</th>
            <td className="border w-3/5 p-1">
              {[
                purchase.dest_family_name,
                purchase.dest_given_name,
              ].join(' ')}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">電話番号</th>
            <td className="border w-3/5 p-1">{purchase.dest_tel}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">郵便番号</th>
            <td className="border w-3/5 p-1">
              {('0000000' + Number(purchase.dest_postal_code)).slice(-7)}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所1</th>
            <td className="border w-3/5 p-1">
              {purchase.dest_address_level1}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所2</th>
            <td className="border w-3/5 p-1">
              {purchase.dest_address_level2}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所3</th>
            <td className="border w-3/5 p-1">
              {purchase.dest_address_line1}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所4</th>
            <td className="border w-3/5 p-1">
              {purchase.dest_address_line2}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="border bg-gray-200" colSpan={2}>
              配送元
            </th>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">組織名</th>
            <td className="border w-3/5 p-1">
              {purchase.src_organization}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">名称</th>
            <td className="border w-3/5 p-1">
              {[
                purchase.src_family_name,
                purchase.src_given_name,
              ].join(' ')}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">電話番号</th>
            <td className="border w-3/5 p-1">{purchase.src_tel}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">郵便番号</th>
            <td className="border w-3/5 p-1">
              {('0000000' + Number(purchase.src_postal_code)).slice(-7)}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所1</th>
            <td className="border w-3/5 p-1">
              {purchase.src_address_level1}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所2</th>
            <td className="border w-3/5 p-1">
              {purchase.src_address_level2}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所3</th>
            <td className="border w-3/5 p-1">
              {purchase.src_address_line1}
            </td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">住所4</th>
            <td className="border w-3/5 p-1">
              {purchase.src_address_line2}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="border bg-gray-200" colSpan={2}>
              その他
            </th>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">時間指定</th>
            <td className="border w-3/5 p-1">{purchase.delivery_time}</td>
          </tr>
          <tr>
            <th className="border bg-gray-200 w-2/5 p-1">備考</th>
            <td className="border w-3/5 p-1">{purchase.note}</td>
          </tr>
        </tbody>
      </table>
      <p className="text-right">
        <Link
          to={`/purchases/${purchase.purchase_id}`}
          className="text-blue-500 hover:text-blue-800"
        >
          支払い情報詳細へ
        </Link>
      </p>
    </section>
  )
}

export default Purchase
