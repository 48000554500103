import React from 'react'
import { CouponForm } from 'components/Coupon'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import { columns } from './columns'
import { ButtonAdd } from 'components/Button'

const queryGen = () => {
  let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/coupons`
  return url
}

export const CouponsPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [showForm, setShowForm] = React.useState(false)
  const [clickedCoupon, setClickedCoupon] = React.useState('')
  const [token, setToken] = React.useState<string | undefined>('')
  const [coupons, setCoupons] = React.useState<any[] | null>(null)

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const fetchCoupons = React.useCallback(() => {
    setCoupons(null)
    axios.defaults.headers.common['Authorization'] = token
    const u = queryGen()
    axios
      .get(u)
      .then((res) => {
        if (res.data.data) {
          setCoupons(res.data.data)
        } else if (res.data.data === null) {
          setCoupons([])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [token])

  // データ取得
  React.useEffect(() => {
    if (token === '') {
      return
    }
    fetchCoupons()
  }, [token, fetchCoupons])

  if (!coupons) {
    return <Loading text="クーポン情報読み込み中..." />
  }

  const navigateToCoupon = (e: React.SyntheticEvent, row: any) => {
    setClickedCoupon(row.getData().id)
    setShowForm(true)
  }

  const deleteCoupon = (e: React.SyntheticEvent, row: any) => {
    e.preventDefault()
    const yes = window.confirm(
      '右クリックしたクーポンを削除しますがよろしいですか？',
    )
    if (yes) {
      axios.defaults.headers.common['Authorization'] = token
      axios
        .delete(
          `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/coupons/${
            row.getData().id
          }`,
        )
        .then((res) => {
          fetchCoupons()
        })
        .catch((err: Error) => {
          alert(err)
          fetchCoupons()
        })
    }
  }

  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeCoupons-filter', '')
    localStorage.setItem('tabulator-backofficeCoupons-sort', '')
    localStorage.setItem('tabulator-backofficeCoupons-columns', '')
    window.location.reload()
  }

  return (
    <div className="flex flex-col">
      <div className="h-12 p-2 flex justify-between">
        <ButtonAdd
          type="button"
          onClick={() => {
            setShowForm(true)
          }}
        />
        <div className="flex items-center justify-start">
          <button
            className="border px-4 rounded hover:bg-gray-200 mx-2"
            onClick={fetchCoupons}
          >
            データ再取得
          </button>
        </div>
        <div className="flex items-center justify-end">
          <button
            className="border px-4 rounded hover:bg-gray-200 mx-2"
            onClick={clearTabulatorPersistence}
          >
            テーブル設定初期化
          </button>
        </div>
      </div>
      <ReactTabulator
        height={window.innerHeight - 80}
        data={coupons}
        columns={columns}
        tooltips={true}
        layout="fitData"
        rowClick={navigateToCoupon}
        options={{
          persistence: true,
          persistenceMode: 'local',
          persistenceID: 'backofficeCoupons',
          persistenceLayout: true,
          movableColumns: true,
          rowContextMenu: [
            {
              label: 'クーポン番号をコピー',
              action: function (e: any, row: any) {
                navigator.clipboard.writeText(row.getData().code)
              },
            },
            { separator: true },
            {
              label: '💣 クーポンを削除',
              action: function (e: any, row: any) {
                deleteCoupon(e, row)
              },
            },
          ],
        }}
      />
      {showForm && (
        <div className="fixed inset-0 flex justify-center items-center -mt-64">
          <div className="">
            <CouponForm
              couponId={clickedCoupon}
              onClose={() => {
                setShowForm(false)
                setClickedCoupon('')
                fetchCoupons()
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CouponsPage
