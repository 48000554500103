import * as React from 'react'
import hitkun from 'images/hitkun_0-16.gif'

const NotFoundPage: React.FC = () => {
  return (
    <div className="w-screen h-screen flex justify-center mx-auto">
      <div className="flex flex-col justify-center items-center">
        <img src={hitkun} alt="キョロキョロ..." className="h-48" />
        <div className="text-4xl font-bold p-8 text-center">
          ページが見つかりません
          <br />
          もしくはまだ作ってません(スイマセン)
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
