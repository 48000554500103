import * as React from 'react'
import axios from 'axios'

export const useSyncSheet = () => {
  const [isSyncing, setIsSyncing] = React.useState(false)
  const syncToSheet = React.useCallback(() => {
    if (isSyncing) {
      return
    }
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/syncsheet`
    setIsSyncing(true)
    axios({
      url: endpoint,
      method: 'get',
    })
      .then((res) => {
        setIsSyncing(false)
        if (res.status === 200) {
          window.alert('同期が完了しました')
        }
      })
      .catch((err) => {
        console.error(err)
        setIsSyncing(false)
        window.alert('エラーが発生しました')
      })
  }, [isSyncing])
  return { isSyncing, syncToSheet }
}
