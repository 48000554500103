import React from 'react'
import { templates } from './templates'

interface Props {
  onClose: () => void
  onSubmit: (data: FormData) => void
  user: any
  order: any
  purchase: any
  products: any[]
}

export const SendMail = (props: Props) => {
  const { onClose, onSubmit, user, order, purchase, products } = props
  const [body, setBody] = React.useState('')
  const [title, setTitle] = React.useState('')
  const handleSubmit = React.useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault()
      const data = new FormData(ev.currentTarget)
      data.set('purchase_id', purchase.purchase.purchase_id)
      data.set('order_id', order.order_id)
      onSubmit(data)
    },
    [onSubmit, order.order_id, purchase.purchase.purchase_id],
  )
  const handleSelectMailType = (
    ev: React.SyntheticEvent<HTMLSelectElement>,
  ) => {
    ev.preventDefault()
    const t = templates(user, purchase, products) as any
    const tmpl = t[ev.currentTarget.value]
    setTitle(tmpl.title)
    setBody(tmpl.body)
  }
  const userName = () => {
    return [user.organization, user.family_name, user.given_name]
      .join(' ')
      .trim()
  }
  return (
    <div className="fixed h-full w-full top-0 left-0 flex items-center justify-center z-30">
      <div
        className="absolute h-full w-full top-0 left-0 bg-black opacity-50 z-40"
        onClick={onClose}
      />
      <form
        onSubmit={handleSubmit}
        className="absolute bg-white z-50 w-1/2 min-h-64 p-4 rounded shadow"
      >
        <h1 className="text-2xl p-2 font-bold">各種通知送信</h1>
        <div className="p-2">
          <p className="whitespace-no-wrap">
            送信するメールの種類を選択することで、
            <wbr />
            各項目に値(テンプレート)がセットされます。
          </p>
        </div>
        <div className="p-2">
          <div>
            <strong>通知種別</strong>
          </div>
          <select
            name="mail_type"
            className="border rounded p-1"
            onChange={handleSelectMailType}
          >
            <option value=""></option>
            <option value="nyuko">入稿案内</option>
            <option value="bank">入金案内(銀行振り込み)</option>
            <option value="vbank">入金案内(バーチャルバンク)</option>
          </select>
        </div>
        <div className="p-2">
          <div className="flex py-2">
            <div>
              <div>
                <strong>宛名</strong>
              </div>
              <input
                type="text"
                name="name"
                className="border border-solid border-gray-300 rounted w-64 p-2 font-mono"
                defaultValue={`${userName()} 様`}
              />
            </div>
            <div>
              <div>
                <strong>メールアドレス</strong>
              </div>
              <input
                type="text"
                name="email"
                className="border border-solid border-gray-300 rounted w-64 p-2 font-mono"
                defaultValue={user.email}
              />
            </div>
          </div>
          <div className="py-2">
            <div>
              <strong>件名</strong>
            </div>
            <input
              type="text"
              name="title"
              className="border border-solid border-gray-300 rounted w-full p-2 font-mono"
              defaultValue={title}
            />
          </div>
          <div className="py-2">
            <div>
              <strong>本文</strong>
            </div>
            <textarea
              name="body"
              className="border border-solid border-gray-300 rounted w-full p-2 font-mono"
              rows={20}
              defaultValue={body}
            ></textarea>
          </div>
          <div className="py-2">
            <div>
              <strong>添付ファイル</strong>
            </div>
          </div>
          <input type="file" name="file" />
        </div>
        <div className="flex justify-end">
          <div className="p-1">
            <button
              className="button p-2 bg-green-500 rounded text-white hover:bg-green-600"
              type="submit"
            >
              送信
            </button>
          </div>
          <div className="p-1">
            <button
              className="button p-2 bg-red-500 rounded text-white hover:bg-red-600"
              type="button"
              onClick={props.onClose}
            >
              キャンセル
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export { SendMailLog } from './log'
