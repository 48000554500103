import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import { Button } from 'components/Button'
import { saveAs } from 'file-saver'

type Props = {
  orderId: string
}

const UserData: React.FC<Props> = (props) => {
  const { orderId } = props
  const { getTokenSilently } = useAuth0()

  const downloadDraft = React.useCallback(async () => {
    const token = await getTokenSilently()
    const now = new Date().getTime()
    axios.defaults.headers.common['Authorization'] = token
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${orderId}/draft?${now}`,
      {
        method: 'get',
        responseType: 'blob',
      },
    )
      .then((res) => {
        const blob = new Blob([res.data])
        saveAs(blob, `${orderId}.zip`)
      })
      .catch((err) => {
        alert('入稿データが存在しないか、開くのに失敗しました')
      })
  }, [getTokenSilently, orderId])

  return (
    <section className="p-2">
      <Button type="button" onClick={downloadDraft} color="primary">
        入稿データをダウンロード
      </Button>
    </section>
  )
}

export default UserData
