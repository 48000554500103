import React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import { useSyncSheet } from 'hooks'

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const { isSyncing, syncToSheet } = useSyncSheet()

  return (
    <div className="flex justify-between shadow whitespace-no-wrap overflow-x-scroll md:overflow-x-hidden print:hidden">
      <div className="p-2">
        <Logo />
      </div>
      <div className="flex jutify-end items-center">
        {isSyncing ? (
          <button
            type="button"
            className="border bg-gray-500 text-white rounded px-2 focus:outline-none cursor-default"
          >
            同期中...
          </button>
        ) : (
          <button
            type="button"
            className="border bg-blue-500 text-white rounded px-2 hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
            onClick={() => {
              if (!isSyncing) {
                syncToSheet()
              }
            }}
          >
            シート同期
          </button>
        )}
        <Link to="/orders">
          <div className="p-2">注文管理</div>
        </Link>
        <Link to="/estimates">
          <div className="p-2">見積管理</div>
        </Link>
        <Link to="/purchases">
          <div className="p-2">支払管理</div>
        </Link>
        <Link to="/users">
          <div className="p-2">ユーザー管理</div>
        </Link>
        <Link to="/addresses">
          <div className="p-2">配送元＆配送先管理</div>
        </Link>
        <Link to="/holidays">
          <div className="p-2">休日管理</div>
        </Link>
        <Link to="/coupons">
          <div className="p-2">クーポン管理</div>
        </Link>
        <div className="p-2">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect({})}>ログイン</button>
          )}
          {isAuthenticated && (
            <button onClick={() => logout()}>ログアウト</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavBar
