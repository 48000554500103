import React from 'react'
import Input from 'components/Form/Input'
import { Field, FieldProps, Form, Formik } from 'formik'

interface Props {
  onClose: () => void
  onSubmit: (values: any) => void
  user: any
}

export const UserInfo = (props: Props) => {
  const d = props.user
  const handleClose = () => {
    props.onClose()
  }
  const handleSubmit = (values: any) => {
    props.onSubmit(values)
  }
  return (
    <div className="fixed h-full w-full top-0 left-0 flex items-center justify-center z-30">
      <div
        className="absolute h-full w-full top-0 left-0 bg-black opacity-50 z-40"
        onClick={props.onClose}
      />
      <div className="absolute bg-white z-50 w-1/2 min-h-64 p-4 rounded shadow overflow-scroll max-h-screen">
        <h1 className="text-2xl p-2 font-bold">お客様情報の変更</h1>
        <div className="p-2">
          <p>お客様情報を変更します</p>
          <ul className="p-2 list-disc ml-4">
            <li>
              お客様にお客様情報を変更した旨は
              <strong className="text-red-700">通知されません</strong>
            </li>
            <li className="text-red-500">
              ※原則はお客様自身で変更していただくようにしてください（セキュリティの関係です）
            </li>
            <li className="text-red-500">※パスワードの変更は出来ません</li>
          </ul>
        </div>
        <Formik
          initialValues={{ ...d }}
          onSubmit={(values, actions) => {
            console.log(values)
            handleSubmit(values)
          }}
        >
          {(props) => (
            <>
              <Form className="flex-1">
                <div className="w-3/4 mx-auto">
                  <Field name="email">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input type="text" label="メールアドレス" {...field} />
                      )
                    }}
                  </Field>
                  <Field name="organization">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="組織" {...field} />
                    }}
                  </Field>
                  <div className="flex">
                    <Field name="family_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return <Input type="text" label="姓" {...field} />
                      }}
                    </Field>
                    <Field name="given_name">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return <Input type="text" label="名" {...field} />
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="family_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return <Input type="text" label="セイ" {...field} />
                      }}
                    </Field>
                    <Field name="given_kana">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return <Input type="text" label="メイ" {...field} />
                      }}
                    </Field>
                  </div>
                  <div className="flex">
                    <Field name="postal_code">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return (
                          <Input type="postal" label="郵便番号" {...field} />
                        )
                      }}
                    </Field>
                    <Field name="address_level1">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        return <Input type="text" label="都道府県" {...field} />
                      }}
                    </Field>
                  </div>
                  <Field name="address_level2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="市区町村" {...field} />
                    }}
                  </Field>
                  <Field name="address_line1">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="以降の住所" {...field} />
                    }}
                  </Field>
                  <Field name="address_line2">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Input
                          type="text"
                          label="ビル名、部屋番号等"
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <Field name="tel">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="電話番号" {...field} />
                    }}
                  </Field>
                  <Field name="fax">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="FAX" {...field} />
                    }}
                  </Field>
                  <Field name="emergency_tel">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return <Input type="text" label="緊急連絡先" {...field} />
                    }}
                  </Field>
                </div>
                <div className="flex justify-end">
                  <div className="p-1">
                    <button
                      className="button p-2 bg-green-500 rounded text-white hover:bg-green-600"
                      type="submit"
                    >
                      変更する
                    </button>
                  </div>
                  <div className="p-1">
                    <button
                      className="button p-2 bg-red-500 rounded text-white hover:bg-red-600"
                      type="button"
                      onClick={handleClose}
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}
