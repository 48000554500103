import * as React from 'react'
import { ReactTabulator } from 'react-tabulator'
import { useAuth0 } from 'react-auth0-spa'
import axios from 'axios'
import Loading from 'components/Loading'
import dayjs from 'dayjs'

const UsersPage: React.FC = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [users, setUsers] = React.useState<any[] | null>(null)
  const [filterKeyword, setFilterKeyword] = React.useState<string>('')

  // トークンセット
  React.useEffect(
    () => {
      getTokenSilently().then((t: string | undefined) => {
        setToken(t)
      })
    },
    [getTokenSilently],
  )

  const fetchUsers = React.useCallback(
    () => {
      setUsers(null)
      axios.defaults.headers.common['Authorization'] = token
      axios
        .get(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/api/v2/admin/usersummaries?keyword=${filterKeyword}`,
        )
        .then((res) => {
          if (res.data.data) {
            setUsers(res.data.data)
          } else if (res.data.data === null) {
            setUsers([])
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [token, filterKeyword],
  )

  // データ取得
  React.useEffect(
    () => {
      if (token === '') {
        return
      }
      fetchUsers()
    },
    [token, fetchUsers],
  )

  if (!users) {
    return <Loading text="ユーザー情報読み込み中..." />
  }

  const columns = [
    { title: 'ID', field: 'id', width: 60, align: 'center' },
    {
      title: '登録日時',
      field: 'created_at',
      align: 'center',
      width: 150,
      formatter: (cell: any) => {
        const unixtime = cell.getValue() * 1000
        if (unixtime === 0) {
          return '--'
        }
        return dayjs(unixtime).format('YYYY/MM/DD hh:mm:ss')
      },
    },
    {
      title: 'Email',
      field: 'email',
      align: 'left',
      width: 150,
    },
    {
      title: '組織名',
      field: 'organization',
      align: 'left',
      width: 180,
    },
    {
      title: '姓',
      field: 'family_name',
      align: 'left',
      width: 70,
    },
    {
      title: '名',
      field: 'given_name',
      align: 'left',
      width: 70,
    },
    { title: 'セイ', field: 'family_kana', align: 'left', width: 80 },
    { title: 'メイ', field: 'given_kana', align: 'left', width: 80 },
    {
      title: '保有ポイント',
      field: 'holding_point',
      align: 'right',
      width: 80,
    },
    {
      title: '納品数',
      field: 'num_orders',
      align: 'right',
      width: 80,
    },
    { title: '郵便番号', field: 'postal_code', width: 80 },
    {
      title: '住所1',
      field: 'address_level1',
      align: 'left',
      width: 75,
    },
    { title: '住所2', field: 'address_level2', width: 120 },
    { title: '住所3', field: 'address_line1', align: 'left', width: 150 },
    { title: '住所4', field: 'address_line2', align: 'left', width: 80 },
    {
      title: '電話',
      field: 'tel',
      align: 'left',
      width: 80,
    },
    {
      title: 'FAX',
      field: 'fax',
      align: 'left',
      width: 80,
    },
    {
      title: '緊急連絡先',
      field: 'emergency_tel',
      align: 'right',
      width: 80,
    },
  ]

  const navigateToUser = (e: any, row: any) => {
    console.log('まだ')
    // history.push(`/users/${row.getData().userId}`)
  }

  const handleInputKeyword = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setFilterKeyword(ev.currentTarget['filter-keyword'].value)
  }

  const clearTabulatorPersistence = () => {
    localStorage.setItem('tabulator-backofficeUsers-filter', '')
    localStorage.setItem('tabulator-backofficeUsers-sort', '')
    localStorage.setItem('tabulator-backofficeUsers-columns', '')
    window.location.reload()
  }
  return (
    <div className="flex flex-col">
      <div className="h-8 p-2 flex justify-between">
        <div className="flex items-center justify-start">
          <form onSubmit={handleInputKeyword}>
            フィルタ:{' '}
            <input
              className="buser rounded px-2"
              name="filter-keyword"
              type="text"
              defaultValue={filterKeyword}
            />
            <button type="submit" className="hidden" />
          </form>
          <button
            className="buser px-4 rounded hover:bg-gray-200 mx-2"
            onClick={fetchUsers}
          >
            データ再取得
          </button>
        </div>
        <div className="flex items-center justify-end">
          <button
            className="buser px-4 rounded hover:bg-gray-200 mx-2"
            onClick={clearTabulatorPersistence}
          >
            テーブル設定初期化
          </button>
        </div>
      </div>
      <ReactTabulator
        height={window.innerHeight - 80}
        data={users}
        columns={columns}
        tooltips={true}
        layout="fitData"
        rowClick={navigateToUser}
        options={{
          persistence: true,
          persistenceMode: 'local',
          persistenceID: 'backofficeUsers',
          persistenceLayout: true,
          movableColumns: true,
        }}
      />
    </div>
  )
}

export default UsersPage
