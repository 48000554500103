import { useFormikContext } from 'formik'
import * as React from 'react'
import Papa from 'papaparse'
import cloneDeep from 'lodash.clonedeep'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'

type Props = {
  onClick: (name: string) => void
  products: any
  user: any
  onDownloadInvoice: () => void
}

const productName = (productType: string, id: number, products: any) => {
  for (const p of products[productType]) {
    if (id === p.value) {
      return p.name
    }
  }
}

export const Controller = (props: Props) => {
  const { values } = useFormikContext()
  const { products, user, onDownloadInvoice } = props
  const handleDownloadCSV = () => {
    const v = cloneDeep(values) as any
    v.organization = user.organization
    v.family_name = user.family_name
    v.given_name = user.given_name
    v.created_at = dayjs(new Date(v.created_at * 1000)).format(
      'YYYY/MM/DD HH:mm:ss',
    )
    v.updated_at = dayjs(new Date(v.updated_at * 1000)).format(
      'YYYY/MM/DD HH:mm:ss',
    )
    v.deleted_at =
      v.deleted_at > 0
        ? dayjs(new Date(v.deleted_at * 1000)).format('YYYY/MM/DD HH:mm:ss')
        : ''
    v.shipping_date = dayjs(new Date(v.shipping_date * 1000)).format(
      'YYYY/MM/DD',
    )
    v.material = productName('material', v.product_id, products)
    v.lamination = productName('lamination', v.lamination, products)
    v.adhesive = productName('adhesive', v.adhesive, products)
    v.halfcut = productName('halfcut', v.halfcut, products)
    v.cutpath = productName('cutpath', v.cutpath, products)
    v.slit = productName('slit', v.slit, products)
    v.delivery_form = productName('deliveryForm', v.delivery_form, products)
    v.white_ink = productName('whiteInk', v.white_ink, products)
    v.send_copy = productName('sendCopy', v.send_copy, products)
    v.cutline_service = productName(
      'cutlineService',
      v.cutline_service,
      products,
    )
    v.whitedata_service = productName(
      'whitedataService',
      v.whitedataService,
      products,
    )
    const orderId = `${v.order_id}`
    v.order_id = `'${orderId}`
    const csv = Papa.unparse([v])
    const blob = new Blob(['\ufeff', csv], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `${orderId}.csv`)
  }
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white py-4 px-4 border-t whitespace-no-wrap overflow-x-scroll">
      <div className="flex">
        <div className="p-1">
          <button
            className="button h-full rounded bg-green-500 text-white py-1 px-2 hover:bg-green-600 focus:outline-none active:outline-none"
            type="submit"
          >
            データを更新する
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('sendMail')
            }}
          >
            各種メール通知
          </button>
        </div>

        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('datachecked')
            }}
          >
            データチェック完了
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-gray-500 text-white py-1 px-2 hover:bg-gray-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('datacheckedMultiple')
            }}
            disabled={true}
          >
            まとめてデータチェック完了
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('shipment')
            }}
          >
            発送処理
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('shippedMultiple')
            }}
          >
            まとめて発送済
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('deliveredMultiple')
            }}
          >
            まとめて納品済
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('doneAgain')
            }}
          >
            注文完了メール再送
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('deliveryInfo')
            }}
          >
            配送情報編集
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={() => {
              props.onClick('customerInfo')
            }}
          >
            お客様情報編集
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={handleDownloadCSV}
          >
            CSV
          </button>
        </div>
        <div className="p-1">
          <button
            className="button h-full rounded bg-blue-500 text-white py-1 px-2 hover:bg-blue-600 focus:outline-none active:outline-none"
            type="button"
            onClick={onDownloadInvoice}
          >
            請求書DL
          </button>
        </div>
      </div>
    </div>
  )
}
