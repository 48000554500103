import React from 'react'
import axios from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import { TypeCoupon } from 'types'
import Input from 'components/Form/Input'
import { Button } from 'components/Button'
import dayjs from 'dayjs'

type Props = {
  couponId?: string
  onClose: () => void
}

export const CouponForm = (props: Props) => {
  const { couponId, onClose } = props
  const [coupon, setCoupon] = React.useState<TypeCoupon>()
  const [loading, setLoading] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (couponId) {
      axios(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/coupons/${couponId}`,
        {
          method: 'get',
        },
      )
        .then((res) => {
          setLoading(false)
          const data = res.data.data
          data.created_at = dayjs.unix(data.created_at).format('YYYY/MM/DD')
          data.updated_at = dayjs.unix(data.updated_at).format('YYYY/MM/DD')
          data.published_at = dayjs.unix(data.published_at).format('YYYY/MM/DD')
          data.expiration = dayjs.unix(data.expiration).format('YYYY/MM/DD')
          setCoupon(data)
        })
        .catch(() => {
          setLoading(false)
          alert('更新処理に失敗しました')
        })
    } else {
      setCoupon({
        id: couponId ? couponId : '',
        created_at: dayjs
          .unix(new Date().getTime() / 1000)
          .format('YYYY/MM/DD'),
        updated_at: dayjs
          .unix(new Date().getTime() / 1000)
          .format('YYYY/MM/DD'),
        deleted_at: dayjs
          .unix(new Date().getTime() / 1000)
          .format('YYYY/MM/DD'),
        published_at: dayjs
          .unix(new Date().getTime() / 1000)
          .format('YYYY/MM/DD'),
        code: '',
        amount: 500,
        rate: 0,
        expiration: dayjs
          .unix(new Date().getTime() / 1000)
          .format('YYYY/MM/DD'),
        usage_limit: 1,
        minimum_amount: 1000,
      })
    }
  }, [couponId])
  return (
    <div className="">
      <Formik
        enableReinitialize={true}
        initialValues={{ ...coupon }}
        onSubmit={(values: any) => {
          setLoading(true)
          const data = { ...values }
          if (!values) {
            return
          }
          data.updated_at = dayjs(values.updated_at).unix()
          data.deleted_at = 0
          data.expiration = dayjs(values.expiration).unix()
          data.rate = values.rate > 1 ? values.rate / 100 : values.rate
          if (values.id) {
            data.expiration = dayjs(values.expiration).endOf('day').unix()
            data.published_at = dayjs(values.published_at).unix()
            axios(
              `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/coupons/${couponId}`,
              {
                method: 'put',
                data: data,
              },
            )
              .then((res) => {
                setLoading(false)
                setCoupon(res.data.data)
                onClose()
              })
              .catch(() => {
                setLoading(false)
                alert('更新処理に失敗しました')
              })
          } else {
            data.created_at = dayjs(values.created_at).unix()
            data.published_at = dayjs(values.published_at).unix()
            axios(
              `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/coupons`,
              {
                method: 'post',
                data: data,
              },
            )
              .then((res) => {
                setLoading(false)
                setCoupon(res.data.data)
                onClose()
              })
              .catch(() => {
                setLoading(false)
                alert('更新処理に失敗しました')
              })
          }
        }}
      >
        {(props) => {
          const { setFieldValue } = props
          return (
            <div
              className="border rounded bg-white shadow mx-auto p-4"
              style={{ width: 600 }}
            >
              <Form>
                <h1 className="text-3xl font-bold p-1">クーポン発行・編集</h1>
                <div className="flex items-center">
                  <Field name="id">
                    {(fieldProps: FieldProps) => {
                      const { field, meta } = fieldProps
                      return (
                        <div className="w-full p-1">
                          <Input
                            type="text"
                            label="ID"
                            setFieldValue={setFieldValue}
                            disabled={true}
                            {...field}
                          />
                          {meta.touched && meta.error && meta.error}
                        </div>
                      )
                    }}
                  </Field>
                  <Field name="code">
                    {(fieldProps: FieldProps) => {
                      const { field, meta } = fieldProps
                      return (
                        <div className="w-full p-1">
                          <Input
                            type="text"
                            label="クーポン番号"
                            setFieldValue={setFieldValue}
                            {...field}
                          />
                          {meta.touched && meta.error && meta.error}
                        </div>
                      )
                    }}
                  </Field>
                  <div className="flex items-center">
                    <Button
                      onClick={() => {
                        setLoading(true)
                        axios(
                          `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/gen-coupon-code`,
                          {
                            method: 'get',
                          },
                        )
                          .then((res) => {
                            setLoading(false)
                            setFieldValue('code', res.data.data)
                          })
                          .catch(() => {
                            setLoading(false)
                          })
                      }}
                    >
                      クーポン番号生成
                    </Button>
                  </div>
                </div>

                <div className="flex">
                  <Field name="amount">
                    {(fieldProps: FieldProps) => {
                      const { field, meta } = fieldProps
                      return (
                        <div className="w-full p-1">
                          <Input
                            type="text"
                            label="割引額"
                            setFieldValue={setFieldValue}
                            {...field}
                          />
                          {meta.touched && meta.error && meta.error}
                        </div>
                      )
                    }}
                  </Field>

                  <Field name="rate">
                    {(fieldProps: FieldProps) => {
                      const { field, meta } = fieldProps
                      return (
                        <div className="w-full p-1">
                          <Input
                            type="text"
                            label="割引率"
                            setFieldValue={setFieldValue}
                            {...field}
                          />
                          {meta.touched && meta.error && meta.error}
                        </div>
                      )
                    }}
                  </Field>
                </div>

                <Field name="expiration">
                  {(fieldProps: FieldProps) => {
                    const { field, meta } = fieldProps
                    return (
                      <div className="w-full p-1">
                        <Input
                          type="text"
                          label="有効期限(YYYY/MM/DD) - 指定日の23:59:59まで有効"
                          setFieldValue={setFieldValue}
                          {...field}
                        />
                        {meta.touched && meta.error && meta.error}
                      </div>
                    )
                  }}
                </Field>

                <Field name="usage_limit">
                  {(fieldProps: FieldProps) => {
                    const { field, meta } = fieldProps
                    return (
                      <div className="w-full p-1">
                        <Input
                          type="text"
                          label="有効回数"
                          setFieldValue={setFieldValue}
                          {...field}
                        />
                        {meta.touched && meta.error && meta.error}
                      </div>
                    )
                  }}
                </Field>

                <Field name="minimum_amount">
                  {(fieldProps: FieldProps) => {
                    const { field, meta } = fieldProps
                    return (
                      <div className="w-full p-1">
                        <Input
                          type="text"
                          label="利用最低額(円)"
                          setFieldValue={setFieldValue}
                          {...field}
                        />
                        {meta.touched && meta.error && meta.error}
                      </div>
                    )
                  }}
                </Field>
                <Field name="note">
                  {(fieldProps: FieldProps) => {
                    const { field, meta } = fieldProps
                    return (
                      <div className="w-full p-1">
                        <Input
                          type="text"
                          label="備考"
                          setFieldValue={setFieldValue}
                          {...field}
                        />
                        {meta.touched && meta.error && meta.error}
                      </div>
                    )
                  }}
                </Field>
                <div className="p-1 flex justify-between">
                  <Button type="button" onClick={onClose}>
                    キャンセル
                  </Button>
                  <Button type="submit" color="primary" requesting={loading}>
                    保存
                  </Button>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}
